<template>
  <ReportGroupQuestions :key="keyOfComponent" />
</template>

<script>
import ReportGroupQuestions from "../../components/reportGroupQuestions/index.vue";
export default {
     data(){
    return {
      keyOfComponent: 1
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(data) {
        this.keyOfComponent = data.params.reportGroupId
      },
    },
  },
  components: {
    ReportGroupQuestions,
  },
};
</script>

<style>
</style>