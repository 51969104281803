<template>
  <div>
    <div class="mb-4 d-flex justify-content-end">
      <button
        class="btn btn-primary d-flex"
        @click="showNewQuestionModal = true"
      >
        <i class="material-icons-outlined">add</i>
        <span class="ml-2"> New Question</span>
      </button>
    </div>
    <div class="my-3">
      <router-link :to="`/reports/${$route.params.reportId}`">
        <button class="mx-2 btn-info btn btn-sm d-flex">
          <i class="material-icons-outlined">chevron_left</i> Back
        </button>
      </router-link>
    </div>
    <div style="width: calc(100vw - 400px)">
      <!-- SU AN HIZLI GEÇİŞ MENÜLERİ KALDIRILDI -->
      <!-- <router-link
        v-for="(list, i) in reportGroupList"
        :key="i"
        :to="`/reports/${$route.params.reportId}/reportGroups/${list.groupId}`"
      >
        <button
          :class="`btn btn-${
            $route.params.reportGroupId == list.groupId ? 'info' : 'light'
          } m-2`"
        >
          {{ list.description }}
        </button>
      </router-link> -->
    </div>
    <div class="row justify-content-end">
      <div class="col-12 col-md-6 col-lg-4 my-4">
        <v-select
          :searchable="false"
          label="description"
          :disabled="loading"
          class="style-chooser"
          :value="activeReportGroupList"
          @option:selected="SET_PUSH_REPORT_GROUP_LIST"
          :clearable="false"
          placeholder="Select Report"
          :options="reportGroupList"
        >
        </v-select>
      </div>
    </div>
    <div>
      <div class="card-title">{{activeReportGroupList.description}}</div>
      <cardTableHeader :headers="fields" />
      <cardTableBody
        :allItemsCount="questions.length"
        :items="questions"
        :loading="loadingQuestions"
        :page="1"
        :pages="1"
      >
        <div
          class="
            card
            row
            p-3
            d-flex
            flex-row
            justify-content-sm-end justify-content-xl-start
          "
          v-for="(item, i) in questions"
          :key="i"
        >
          <div
            class="col-1 d-none d-lg-block col-lg-1"
            @click="
              editQuestion = {
                ...item,
                index: i,
                options: [...item.options],
              };
              showModal = true;
            "
          >
            <div class="mx-2">{{ item.id }}</div>
          </div>
          <div class="col-12 col-lg-5 text-primary">
            <div
              @click="
                editQuestion = {
                  ...item,
                  index: i,
                  options: [...item.options],
                };
                showModal = true;
              "
              class="mx-2"
            >
              {{ item.description }}
              <small
                v-if="item.isRequired === 1"
                class="ml-2 badge badge-danger"
                >required</small
              >
              <span
                v-if="
                  item.options.length !== 0 &&
                  (item.questionType === 3 || item.questionType === 4)
                "
                class="float-right badge badge-success"
                >{{ item.options.length }} item{{
                  item.options.length > 1 ? "s" : ""
                }}</span
              >
            </div>
          </div>
          <div class="col-4 col-lg-2 mt-4 mt-lg-0">
            <div
              @click="
                editQuestion = {
                  ...item,
                  index: i,
                  options: [...item.options],
                };
                showModal = true;
              "
            >
              <span
                :class="`btn btn-${
                  SET_QUESTION_TYPE(item.questionType).variant
                } btn-xs btn-block`"
              >
                {{ SET_QUESTION_TYPE(item.questionType).text }}
              </span>
            </div>
          </div>
          <div class="col-3 col-lg-2 mt-4 mt-lg-0">
            <span
              :class="`btn btn-xs btn-block btn-${
                item.isActive === 1 ? 'primary' : 'danger'
              }`"
              >{{ item.isActive === 1 ? "On" : "Off" }}</span
            >
          </div>
          <div class="col-5 col-lg-2 mt-4 mt-lg-0">
            <button
              class="btn btn-warning mx-2 btn-xs"
              @click="
                editQuestion = {
                  ...item,
                  index: i,
                  options: [...item.options],
                };
                showModal = true;
              "
            >
              <i style="font-size: 13px" class="material-icons-outlined mr-2"
                >border_color</i
              >Update
            </button>
          </div>
        </div>
      </cardTableBody>
    </div>
    <modal
      @ok="SEND_NEW_QUESTION_TO_API"
      okText="Save"
      title="New Question"
      v-model="showNewQuestionModal"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <input
          class="form-control"
          id="email"
          maxlength="50"
          :class="{ 'border-danger': NewQuestion.description === '' }"
          v-model="NewQuestion.description"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Question Title"
        />
        <div class="mt-2" v-if="NewQuestion.description === ''">
          <small class="text-danger"> * Question Title is required </small>
        </div>
      </div>
      <div class="mt-4">
        <h6>
          Does this question <strong>has to be</strong> answered?
          <span class="badge badge-danger">required</span>
        </h6>
        <button
          @click="NewQuestion.isRequired = 1"
          :class="`mx-2 btn btn-${
            NewQuestion.isRequired === 1 ? 'success' : 'light'
          }`"
        >
          Yes
        </button>
        <button
          @click="NewQuestion.isRequired = 0"
          :class="`mx-2 btn btn-${
            NewQuestion.isRequired === 0 ? 'success' : 'light'
          }`"
        >
          No
        </button>
        <div class="alert alert-info mt-4" v-if="NewQuestion.isRequired === 1">
          <small
            >You will not be able to proceed to the next step until this
            question is answered.</small
          >
        </div>
      </div>
      <div class="mt-4">
        <h6 class="my-3">
          Choose Question Type <span class="badge badge-danger">required</span>
        </h6>
        <button
          @click="NewQuestion.questionType = typ.Id"
          class="btn btn-sm m-2"
          :class="{
            'btn-info': typ.Id === NewQuestion.questionType,
            'btn-light': typ.Id !== NewQuestion.questionType,
          }"
          v-for="(typ, i) in QuestionTypes"
          :key="i"
        >
          {{ typ.description }}
        </button>
      </div>
      <div
        class="mt-4 text-muted small"
        v-if="NewQuestion.questionType === 3 || NewQuestion.questionType === 4"
      >
        <h6 class="my-3">
          Question Options <span class="badge badge-danger">required</span>
        </h6>
        <p
          class="text-center text-muted"
          v-if="NewQuestion.options.length === 0"
        >
          <small> You can add options in below</small> <br />
          <i class="material-icons-outlined mr-2">arrow_downward</i>
        </p>
        <div v-if="NewQuestion.options.length === 0" class="text-center mt-4">
          <button
            class="btn btn-primary btn-xs px-3"
            @click="NewQuestion.options.push({ description: '' })"
          >
            + New Option
          </button>
        </div>
        <div class="px-4" v-for="(option, i) in NewQuestion.options" :key="i">
          <div class="form-group position-relative">
            <input
              class="form-control small"
              id="email"
              maxlength="50"
              v-model="option.description"
              required
              aria-describedby="emailHelp"
              :placeholder="`${i + 1}. Enter Question Option`"
            />
            <i
              @click="NewQuestion.options.splice(i, 1)"
              class="material-icons-outlined mr-2 input-append-icon"
              >close</i
            >
          </div>
        </div>
        <div class="mt-2" v-if="NewQuestion.options.length !== 0">
          <a
            @click="NewQuestion.options.push({ description: '' })"
            href="javascript:void(0)"
            class="text-white btn btn-primary btn-xs px-3"
            >+ New Option
          </a>
        </div>
      </div>
      <div
        class="mt-4 text-muted small"
        v-else-if="NewQuestion.questionType === 2"
      >
        * Only numbers can be entered.
      </div>
      <div
        class="mt-4 text-muted small"
        v-else-if="NewQuestion.questionType === 1"
      >
        * Number or Text (Letters) can be entered.
      </div>
      <div
        class="mt-4 text-muted small"
        v-else-if="NewQuestion.questionType === 5"
      >
        * Only date can be entered.
      </div>
    </modal>
    <modal
      @ok="SEND_EDIT_QUESTION_TO_API"
      okText="Save Changes"
      title="Update Report Group"
      v-model="showModal"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="editQuestion.description"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Group Name"
        />
      </div>
      <div class="mt-4">
        <h6>
          Does this question <strong>has to be</strong> answered?
          <span class="badge badge-danger">required</span>
        </h6>
        <button
          @click="editQuestion.isRequired = 1"
          :class="`mx-2 btn btn-${
            editQuestion.isRequired === 1 ? 'success' : 'light'
          }`"
        >
          Yes
        </button>
        <button
          @click="editQuestion.isRequired = 0"
          :class="`mx-2 btn btn-${
            editQuestion.isRequired === 0 ? 'success' : 'light'
          }`"
        >
          No
        </button>
        <div class="alert alert-info mt-4" v-if="editQuestion.isRequired === 1">
          <small
            >You will not be able to proceed to the next step until this
            question is answered.</small
          >
        </div>
      </div>
      <div class="mt-4">
        <h6 class="my-3">
          Choose Question Type <span class="badge badge-danger">required</span>
        </h6>
        <button
          @click="editQuestion.questionType = typ.Id"
          class="btn btn-sm m-2"
          :class="{
            'btn-info': typ.Id === editQuestion.questionType,
            'btn-light': typ.Id !== editQuestion.questionType,
          }"
          v-for="(typ, i) in QuestionTypes"
          :key="i"
        >
          {{ typ.description }}
        </button>
      </div>
      <div
        class="mt-4 text-muted small"
        v-if="
          editQuestion.questionType === 3 || editQuestion.questionType === 4
        "
      >
        <h6 class="my-3">
          Question Options <span class="badge badge-danger">required</span>
          <span
            v-if="
              editQuestion.options.filter((o) => o.isRemoved === 0).length !==
                0 &&
              (editQuestion.questionType === 3 ||
                editQuestion.questionType === 4)
            "
            class="float-right badge badge-success"
            >{{
              editQuestion.options.filter((o) => o.isRemoved === 0).length
            }}
            item{{
              editQuestion.options.filter((o) => o.isRemoved === 0).length > 1
                ? "s"
                : ""
            }}</span
          >
        </h6>
        <p
          class="text-center text-muted"
          v-if="editQuestion.options.length === 0"
        >
          <small> You can add options in below</small> <br />
          <i class="material-icons-outlined mr-2">arrow_downward</i>
        </p>
        <div v-if="editQuestion.options.length === 0" class="text-center mt-4">
          <button
            class="btn btn-primary px-3 btn-xs"
            @click="
              editQuestion.options.push({
                id: -1,
                description: '',
                isRemoved: 0,
                isUpdated: 0,
                isActive: 1,
              })
            "
          >
            + New Option
          </button>
        </div>
        <div class="px-4" v-for="(option, i) in editQuestion.options" :key="i">
          <div class="form-group position-relative">
            <input
              class="form-control small"
              :class="{
                'border-danger text-danger line-through':
                  option.isRemoved === 1,
              }"
              id="email"
              maxlength="50"
              v-model="option.description"
              @input="option.isUpdated = 1"
              required
              aria-describedby="emailHelp"
              :placeholder="`${i + 1}. Enter Question Option`"
            />
            <i
              @click="option.isRemoved = option.isRemoved === 1 ? 0 : 1"
              class="material-icons-outlined mr-2 input-append-icon"
              >{{ option.isRemoved === 0 ? "close" : "replay" }}</i
            >
          </div>
        </div>
        <div v-if="editQuestion.options.length !== 0" class="mt-2">
          <a
            @click="
              editQuestion.options.push({
                id: -1,
                description: '',
                isRemoved: 0,
                isUpdated: 0,
                isActive: 1,
              })
            "
            href="javascript:void(0)"
            class="btn btn-xs btn-primary px-3 text-white"
            >+ New Option
          </a>
        </div>
      </div>
      <div
        class="mt-4 text-muted small"
        v-else-if="editQuestion.questionType === 2"
      >
        * Only numbers can be entered.
      </div>
      <div
        class="mt-4 text-muted small"
        v-else-if="editQuestion.questionType === 1"
      >
        * Number or Text (Letters) can be entered.
      </div>
      <div
        class="mt-4 text-muted small"
        v-else-if="editQuestion.questionType === 5"
      >
        * Only date can be entered.
      </div>
      <div class="mt-4">
        <strong>Situation: </strong>
        <button
          @click="editQuestion.isActive = 1"
          class="btn btn-xs mx-2"
          :class="{
            'btn-secondary': editQuestion.isActive === 0,
            'btn-primary': editQuestion.isActive === 1,
          }"
        >
          Active
        </button>
        <button
          @click="editQuestion.isActive = 0"
          class="btn btn-xs mx-2"
          :class="{
            'btn-secondary': editQuestion.isActive === 0,
            'btn-danger': editQuestion.isActive === 0,
          }"
        >
          Archived
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import cardTableHeader from "../ui/table/cardTableHeader.vue";
import cardTableBody from "../ui/table/cardTableBody.vue";
import ReportGroups from "../reportGroups/getReportGroups";
import GroupQuestions from "./getGroupQuestions";
import Modal from "../ui/modal.vue";
export default {
  mixins: [GroupQuestions, ReportGroups],
  data() {
    return {
      NewQuestion: {
        description: "",
        questionType: 1,
        isActive: 1,
        options: [],
        isRequired: 0,
      },
      QuestionTypes: [
        { Id: 1, description: "Text" },
        { Id: 2, description: "Number" },
        { Id: 3, description: "Checkbox" },
        { Id: 4, description: "Single Select" },
        { Id: 5, description: "Date" },
      ],
      alert: { status: false, message: "", variant: "success" },
      showNewQuestionModal: false,
      showModal: false,
      editQuestion: {
        options: [],
      },
      fields: [
        { label: "Id", sort: false, col: 12, md: 6, lg: 1, xl: 1 },
        { label: "Name", sort: false, col: 12, md: 6, lg: 5, xl: 5 },
        { label: "Type", sort: false, col: 12, md: 6, lg: 2, xl: 2 },
        { label: "Situation", sort: false, col: 12, md: 6, lg: 2, xl: 2 },
        { label: "Actions", sort: false, col: 12, md: 6, lg: 2, xl: 2 },
      ],
    };
  },
  components: {
    cardTableHeader,
    cardTableBody,
    Modal,
  },
  methods: {
    SET_PUSH_REPORT_GROUP_LIST(row) {
      this.$router.push(
        `/reports/${this.$route.params.reportId}/reportGroups/${row.groupId}`
      );
    },
    SET_QUESTION_TYPE(type) {
      if (type === 1) return { variant: "light", text: "Text" };
      else if (type === 2) return { variant: "danger", text: "Number" };
      else if (type === 3) return { variant: "success", text: "Checkbox" };
      else if (type === 4) return { variant: "info", text: "Select Box" };
      else if (type === 5) return { variant: "warning", text: "Date" };
      else return { variant: "light", text: "Text" };
    },
    async SEND_NEW_QUESTION_TO_API(e, loading) {
      this.alert.status = false;
      if (
        this.NewQuestion.description === "" ||
        ((this.NewQuestion.questionType === 3 ||
          this.NewQuestion.questionType === 4) &&
          this.NewQuestion.options.length === 0)
      )
        return;
      if (
        (this.NewQuestion.questionType === 3 ||
          this.NewQuestion.questionType === 4) &&
        this.NewQuestion.options.length !== 0
      )
        if (
          this.NewQuestion.options.filter((o) => o.description === "").length >
          0
        )
          return;
      loading();
      const response = await this.$api.post("Questions", {
        description: this.NewQuestion.description,
        questionType: this.NewQuestion.questionType,
        isRequired: this.NewQuestion.isRequired,
        isActive: 1,
        options:
          this.NewQuestion.questionType === 3 ||
          this.NewQuestion.questionType === 4
            ? this.NewQuestion.options
            : [],
        questionGroupId: Number(this.$route.params.reportGroupId),
      });
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Question saved successfully",
        };
        // get question list
        this.GET_QUESTIONS_BY_GROUPID();
        setTimeout(() => {
          this.showNewQuestionModal = false;
          this.alert.status = false;
          this.NewQuestion = {
            description: "",
            questionType: 1,
            isActive: 1,
            options: [],
            isRequired: 0,
          };
        }, 1500);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      loading(false);
    },
    async SEND_EDIT_QUESTION_TO_API(e, loading) {
      console.log(this.editQuestion.options);
      this.alert.status = false;
      if (
        this.editQuestion.description === "" ||
        ((this.editQuestion.questionType === 3 ||
          this.editQuestion.questionType === 4) &&
          this.editQuestion.options.length === 0)
      )
        return;
      if (
        (this.editQuestion.questionType === 3 ||
          this.editQuestion.questionType === 4) &&
        this.editQuestion.options.length !== 0
      )
        if (
          this.editQuestion.options.filter((o) => o.isRemoved !== 1 && o.description === "").length >
          0
        )
          return;
      loading();
      const response = await this.$api.put(
        `Questions/${this.editQuestion.id}`,
        {
          description: this.editQuestion.description,
          isRequired: this.editQuestion.isRequired,
          questionType: this.editQuestion.questionType,
          isActive: this.editQuestion.isActive,
          newOptions: this.editQuestion.options.filter(
            (o) => o.id === -1 && o.isRemoved === 0 && o.description !== ""
          ),
          updatedOptions: this.editQuestion.options.filter(
            (o) =>
              o.id !== -1 &&
              o.isUpdated === 1 &&
              o.isRemoved === 0 &&
              o.description !== ""
          ),
          removedOptions: this.editQuestion.options
            .filter(
              (o) => o.id !== -1 && (o.isRemoved === 1 || o.description === "")
            )
            .map((om) => om.id),
        }
      );
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Question saved successfully",
        };
        this.GET_QUESTIONS_BY_GROUPID();
        setTimeout(() => {
          this.showModal = false;
          this.alert.status = false;
        }, 1500);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      loading(false);
    },
  },
};
</script>

<style>
.input-append-icon {
  position: absolute;
  right: 9px;
  color: #7d7d83;
  top: 15px;
  cursor: pointer;
  z-index: 2;
  font-size: 16px;
}
.input-append-icon:hover {
  color: #131313;
}
.line-through {
  text-decoration: line-through !important;
}
</style>