<template>
  <create-report />
</template>

<script>
import createReport from "../../components/createReport/index.vue";
export default {
  components: {
    createReport,
  },
};
</script>

<style>
</style>