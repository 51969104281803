export default {
    data() {
        return {
            loadingQuestions: true,
            questions: []
        }
    },
    methods: {
        async GET_QUESTIONS_BY_GROUPID() {
            this.loadingQuestions = true;
            const groupId = Number(this.$route.params.reportGroupId);
            const response = await this.$api.get(
                `Questions?groupId=${groupId}`
            );
            if (response.result === "OK" && response.message === "OK") {
                this.questions = response.data.map(d => {
                    return {
                        ...d,
                        options: d.options.map(o => {
                            return {
                                ...o,
                                isRemoved: 0,
                                isUpdated: 0
                            }
                        })
                    }
                })
            } else this.questions = [];
            this.loadingQuestions = false;
        }
    },
    mounted() {
        this.GET_QUESTIONS_BY_GROUPID();
    }
}