<template>
  <div class="container">
    <div class="row justify-content-center" v-if="!isLoadingReportList">
      <div class="col-12" v-for="(report, i) in filteredReports" :key="i">
        <router-link :to="`/create-report/${report.id}`">
          <div
            class="alert text-left"
            :class="{
              'alert-warning': i % 4 == 0,
              'alert-danger': i % 4 == 1,
              'alert-info': i % 4 == 2,
              'alert-success': i % 4 == 3,
            }"
          >
            <p>{{ i + 1 }}. {{ report.description }}</p>
            <div class="d-flex justify-content-end">
              <button class="d-flex btn btn-primary btn-xs">
                Create Report
                <i class="ml-2 material-icons-outlined">arrow_forward</i>
              </button>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div v-else class="text-center">
      <span class="spinner-border mr-2" role="status" aria-hidden="true"></span>
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import ReportTypeActions from "../reportType/getReportType";
export default {
  mixins: [ReportTypeActions],
};
</script>

<style>
</style>